import { registerApp } from '@mfl/framework';
import { currentUser } from '@mfl/platform-shell';

const route = '/v1/account-settings';

export async function register() {
  if (currentUser.isAllowedTo('account.manage')) {
    await registerApp('account-settings', route);
  }
}
